<template>
  <custom-dialog
    :visible.sync="visible"
    :title="title"
    width="60%"
    @close="colseDialog"
    @submit="submit">
    <div>
      <el-form :inline="true" :model="searchForm" label-width="100px" :rules="rules" ref="editAcceptanceForm">
        <el-row>
          <el-col :span="12">
            <el-form-item label="所属机构">
              <el-cascader
                  size="small"
                  clearable
                  v-model="orgaIdArr"
                  :options="copanyListInfo"
                  popper-class="custom-cascader"
                  filterable
                  :props="{
                    checkStrictly: true,
                    expandTrigger: 'hover',
                    value:'orgaPathIds'
                  }"
                  @change="cascaderChange"></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="性别：" prop="userSex" class="selects">
              <el-select v-model="searchForm.userSex" placeholder="请选择">
                <el-option
                    v-for="item in sexList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="证件号：" class="userNo">
              <el-input
                  class="userNo"
                  size="small"
                  v-model="searchForm.userCardId"
                  placeholder="请输入证件号">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="1">
            <div class="btn">
              <el-button
                  size="small"
                  type="primary"
                  style="height: 30px; margin-left: 10px"
                  @click="getInfo">查询</el-button>
            </div>
          </el-col>
        </el-row>
      </el-form>
      <custom-tables
          :data="tableData"
          :total="total"
          noimport="true"
          isadd="true"
          noexport="true"
          isdel="true"
          title="待参会人员列表"
          @selectionChange="selectionChange"
          @handleCurrentChange="handleCurrentChange"
          @handleSizeChange="handleSizeChange">
        <el-table-column type="selection" align="center" width="55">
        </el-table-column>
        <el-table-column
            prop="userName"
            align="center"
            label="姓名"
            width="180">
        </el-table-column>
        <el-table-column
            prop="userSex"
            align="center"
            label="性别"
            width="180">
          <template slot-scope="scope">
            <span v-if="scope.row.userSex == 0">男</span>
            <span v-if="scope.row.userSex == 1">女</span>
          </template>
        </el-table-column>
        <el-table-column prop="userCardId" align="center" label="证件号">
        </el-table-column>
      </custom-tables>
    </div>
  </custom-dialog>
</template>

<script>
import CustomDialog from "../../common/CustomDialog";
import CustomTables from "../../../components/common/CustomTables";
import {sexTypes} from '../../../utils/sexType'
import basic from "../../../api/modules/basic";
import {organizationCascader} from "../../../utils/tool";
import meeting from "../../../api/modules/meeting";
export default {
  name: "EditAcceptance",
  components: { CustomDialog, CustomTables },
  props: ["visible", "title", "item", "meetId"],
  data() {
    return {
      tableData: [],
      name: "",
      total: 0,
      searchForm: {
        currPage: 1,
        pageSize: 10,
      },
      searchFormNO: {
        equipmentNo: "",
        currPage: 1,
        pageSize: 10,
      },
      userTypeList: [
        { label: "操作员", value: 0 },
        { label: "管理员", value: 1 },
      ],
      sexList:sexTypes,
      projList: [],
      equipmentNoList: [],
      rules: {
        userType: [
          { required: true, message: "请选择人员类别", trigger: "blur" },
        ],
        userNo: [
          { required: true, message: "请输入证件号码", trigger: "change" },
          {
            pattern: /^\d{18}$/,
            message: "证件号码必须为18位数字",
            trigger: "change",
          },
        ],
      },
      orgaIdArr: [],
      copanyListInfo:[],
    };
  },
  mounted() {
    this.getCopanyInfo()
  },
  methods: {
    getCopanyInfo(){
      basic.getOrganizationTree({orgaPid:0}).then(res =>{
        // 递归遍历 生成 {value, label, children}
        let data = organizationCascader(res.data)
        this.copanyListInfo = data
      })
    },
    getInfo() {
      // let params = {
      //   projId: this.searchForm.orgaPathIds,
      //   userCardId: this.searchForm.userType,
      //   userSex: this.searchFormNO.projId,
      // };
      meeting.getMayMeetPersonList(this.searchForm).then((res) => {
        if (res.code == 200) {
          console.log(res);
          this.tableData = res.data.list;
          this.total = res.data.total
          // this.searchForm.userId = res.data[0].userId;
        }
      });
    },
    submit() {
      this.$refs.editAcceptanceForm.validate((valid) => {
        if (valid) {
          let params ={
            meetId: this.meetId,
            persType: 0,
            userId:this.selectIdList
          }
          meeting.addMeetPerson(params).then((res) => {
            this.$message({
              message: "新增成功",
              type: "success",
            });
            this.$emit("done");
            this.searchForm.userNo = "";
            this.searchForm.userType = "";
            this.searchFormNO.projId = "";
            this.searchFormNO.equipmentNo = "";
            this.tableData = [];
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    colseDialog() {
      this.$emit("close");
    },
    handleCurrentChange(pageIndex) {
      this.searchForm.currPage = pageIndex;
      this.getInfo();
    },
    handleSizeChange(pageSize) {
      this.searchForm.currPage = 1;
      this.searchForm.pageSize = pageSize;
      this.getInfo();
    },
    selectionChange(val) {
      console.log(val);
      this.selectIdList = [];
      val.forEach((item) => {
        this.selectIdList.push(item.userId);
      });
    },
    cascaderChange(val){
      let valLen = val.length
      this.searchForm.orgaPathIds = val[valLen-1]
    },
  },
};
</script>

<style scoped lang="less">
.edit-form {
  padding: 20px;
}
.line {
  width: 100%;
  height: 1px;
  background: #f0f0f0;
  margin-bottom: 15px;
}
.blankCol {
  height: 40px;
  margin-bottom: 20px;
}
.footer-save {
  position: absolute;
  bottom: 18px;
  right: 250px;
}
/deep/.el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
//   width: 200px !important;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  height: 32px !important;
  line-height: 32px !important;
  outline: 0;
//   padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
}
.btn {
  margin-top: 5px;  
}
/deep/.select .el-input__inner{
    width: 233px;
}
/deep/.userNo .el-input__inner{
    width: 230px;
}
/deep/.selects .el-input__inner{
    width: 230px;
}
</style>

<template>
  <div class="pageMain">
    <search-head @reset="headReset" @search="headSearch">
      <el-form :inline="true" :model="searchForm">
        <el-form-item label="所属酒店：">
          <el-select
            size="small"
            clearable
            v-model="searchForm.hotelId"
            placeholder="请选择所属酒店">
          <el-option
                v-for="item in hotelList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="会议名称：">
          <el-input
            size="small"
            clearable
            v-model="searchForm.placeName"
            placeholder="请输入会议名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="会场：">
          <el-select
            size="small"
            clearable
            v-model="searchForm.placeName"
            placeholder="请选择会场">
            <el-option
              v-for="item in areaParentList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="开始时间：" class="time">
          <el-date-picker
            v-model="searchForm.startTime"
            type="datetime"
            placeholder="选择开始时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="结束时间：" class="time">
          <el-date-picker
            v-model="searchForm.endTime"
            type="datetime"
            placeholder="选择结束时间">
          </el-date-picker>
        </el-form-item>
      </el-form>
    </search-head>
    <custom-tables
      :data="tableData"
      :total="total"
      noimport="true"
      isdel="true"
      title="会场列表"
      @selectionChange="selectionChange"
      @handleCurrentChange="handleCurrentChange"
      @handleSizeChange="handleSizeChange"
      @tableExport="tableExport"
      @tableDel="tableDel"
      @tableAdd="tableAdd">
      <el-table-column type="selection" align="center" width="55">
      </el-table-column>
      <el-table-column prop="meetName" label="会议名称" align="center">
      </el-table-column>
      <el-table-column
        prop="hotelName"
        label="所属酒店"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="placeName"
        label="会场名称"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="startTime"
        label="开始时间"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="endTime"
        label="结束时间"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="createTime"
        label="添加时间"
        align="center"
      ></el-table-column>
      <el-table-column label="操作" align="center" width="300px">
        <template slot-scope="scope">
          <el-button
            type="text"
            link
            style="margin-right: 10px"
            @click="details(scope.row)">详情</el-button>
          <el-button
            type="text"
            link
            style="margin-right: 10px"
            @click="editItem(scope.row)">编辑</el-button>
          <el-button
            type="text"
            link
            style="margin-right: 10px"
            @click="delItem(scope.row)">删除</el-button>
          <el-button
            type="text"
            link
            style="margin-right: 10px"
            @click="participants(scope.row)">参会人员</el-button>
        </template>
      </el-table-column>
    </custom-tables>
    <meetingMangeEdit
      :visible.sync="showDialog"
      :title="dialogTitle"
      :item="item"
      :isEdit="isEdit"
      @close="showDialog = false"
      @done="editDone"
    ></meetingMangeEdit>
    <participants
      :visible.sync="showDialog2"
      :title="dialogTitle2"
      :meet-id="item.meetId"
      @close="showDialog2 = false"
      @done="editDone"
    ></participants>
  </div>
</template>

<script>
import basic from "../../../api/modules/basic";
import meeting from "../../../api/modules/meeting";
import CustomTables from "../../../components/common/CustomTables";
import SearchHead from "../../../components/common/SearchHead";
import meetingMangeEdit from "../../../components/page/meeting/meetingMangeEdit.vue";
import participants from "../../../components/page/meeting/participants.vue";
import { downloadFile } from "../../../utils/tool";

export default {
  name: "meetingMange",
  components: { meetingMangeEdit, SearchHead, CustomTables, participants },
  data() {
    return {
      dialogTitle: "新增公司",
      showDialog: false,
      showDialog2: false,
      dialogTitle2: "",
      total: 0,
      tableData: [],
      searchForm: {
        currPage: 1,
        pageSize: 10,
      },
      item: {},
      areaParentList: [],
      isEdit:false,
      hotelList:[]
    };
  },
  mounted() {
    // this.getAreaInfo(0);
    this.getInfo();
    this.gethotelList()
  },
  methods: {
    getAreaInfo(id) {
      let param = { pid: id };
      basic.getAreaList(param).then((res) => {
        this.areaParentList = res.data;
      });
    },
    getInfo() {
      meeting.getMeetingInfoPageList(this.searchForm).then((res) => {
        this.total = res.data.total;
        this.tableData = res.data.list;
      });
    },
    handleCurrentChange(pageIndex) {
      this.searchForm.currPage = pageIndex;
      this.getInfo();
    },
    handleSizeChange(pageSize) {
      this.searchForm.currPage = 1;
      this.searchForm.pageSize = pageSize;
      this.getInfo();
    },
    editDone() {
      // 编辑成功返回
      this.showDialog = false;
      this.getInfo();
    },
    headReset() {
      this.searchForm = {
        currPage: 1,
        pageSize: 10,
      };
      this.getInfo();
    },
    headSearch() {
      this.searchForm.currPage = 1;
      this.searchForm.pageSize = 10;
      this.getInfo();
    },
    tableDel() {},
    //导出
    tableExport() {
      let param = {
        currPage: this.searchForm.currPage,
        pageSize: this.searchForm.pageSize,
      };
      basic
        .getMeetingInfoExport(param)
        .then((res) => {
          downloadFile(res);
        })
        .catch((err) => {
          console.log(err, "err");
        });
    },
    //格式化json
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]));
    },
    gethotelList() {
      meeting.getHotelList().then((res) => {
        res.data.map((item) => {
          this.hotelList.push({
            label: item.hotelName,
            value: item.hotelId,
          });
        });
      });
    },
    tableAdd() {
      this.item = {};
      this.isEdit=false
      this.dialogTitle = "会议信息";
      this.showDialog = true;
    },
    selectionChange(val) {
      console.log(val);
    },
    editItem(item) {
      meeting.meetingInfoDetial({ meetId: item.meetId }).then((res) => {
        this.isEdit=false
        this.item = res.data;
        this.dialogTitle = "会议信息编辑";
        this.showDialog = true;
      });
    },
    details(item) {
      meeting.meetingInfoDetial({ meetId: item.meetId }).then((res) => {
        this.item = res.data;
        this.dialogTitle = "会议信息详情";
        this.showDialog = true;
        this.isEdit=true
      });
    },
    participants(row) {
      this.item = row
      this.showDialog2 = true;
      this.dialogTitle2 = "参会人员";
    },
    delItem(item) {
      this.$confirm(`此操作将永久删除会议${item.meetName}, 是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          meeting.deleteMeetingInfo(item.meetId).then((res) => {
            this.$message({
              message: "删除成功",
              type: "success",
            });
            this.getInfo();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>

<style scoped lang="less">
/deep/ .time .el-input__inner {
  width: 100%;
  height: 32px;
}
</style>

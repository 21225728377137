<template>
  <el-cascader
      size="small"
      clearable
      class="block-select"
      ref="sourceCasRef"
      :disabled="disabled"
      v-model="dataArr"
      :props="props"
      @change="cascaderChange"></el-cascader>
</template>

<script>
import common from "../../api/modules/common";

export default {
  name: "SourceCascader",
  props: ['checked','disabled','value'],
  watch:{
    value(newVal){
      if(!newVal){
        this.dataArr = []
      }
    },
    checked(newVal){
      console.log('newVal',newVal)
      this.dataArr = newVal
    }
  },
  data() {
    return {
      dataArr:[],
      dataListInfo:[],
      props: {
        lazy: true,
        lazyLoad (node, resolve) {
          console.log('node',node)
          const { level,value } = node;
          let id =  value? value :0
          // 只考虑有且仅有二级
          common.getEquipmentList({equipTypeId:id}).then(res =>{
            if(res.code == 200 && res.data){
              let data = res.data.map(item =>{
                return {
                  value: item.equipTypeId,
                  label: item.equipTypeName,
                  isSpecial: item.isSpecial,  // 1时为特种设备
                  leaf: level >=1
                }
              })
              resolve(data);
            }
          })

        }
      }
    }
  },
  mounted() {
    this.dataArr = this.checked || []
  },
  methods: {
    async getSourceInfo(id){
      // 获取设备类型的接口
      let res = await common.getEquipmentList({equipTypeId:id})
      if(res.code == 200 && res.data){
        let data = res.data.map(item =>{
          return {
            value: item.equipTypeId,
            label: item.equipTypeName,
            isSpecial: item.isSpecial,  // 1时为特种设备
            leaf: true
          }
        })
        return data
      }else {
        return []
      }
    },
    cascaderChange(val){
      if(val){
        let valLen = val.length
        this.$emit('input', val[valLen-1])
      }
      let data =  this.$refs.sourceCasRef.getCheckedNodes()
      let resultData  = data[0].data
      resultData.arr = val
      // let isSpecial = data[0].data.isSpecial  // 1 为特种设备
      this.$emit('change', resultData)
    },
  }
}
</script>

<style scoped>

</style>
<template>
  <custom-dialog
      :visible.sync="visible"
      :title="title"
      width="70%"
      @close="colseDialog"
      isFooter="true"
      :isShow="isEdit">
    <div class="pageMain">
      <search-head @reset="headReset" @search="headSearch">
        <el-form :inline="true" :model="searchForm">
          <el-form-item label="所属机构">
          <el-cascader
              size="small"
              clearable
              v-model="orgaIdArr"
              :options="copanyListInfo"
              popper-class="custom-cascader"
              filterable
              :props="{
                    checkStrictly: true,
                    expandTrigger: 'hover',
                    value:'orgaPathIds'
                  }"
              @change="cascaderChange"></el-cascader>
        </el-form-item>
          <el-form-item label="证件号：">
            <el-input
                size="small"
                clearable
                v-model="searchForm.userCardId"
                placeholder="请输入证件号"
            ></el-input>
          </el-form-item>
          <el-form-item label="性别：">
            <el-select
                size="small"
                clearable
                v-model="searchForm.userSex"
                placeholder="请选择性别">
              <el-option
                  v-for="item in sexList"
                  :key="item.id"
                  :label="item.label"
                  :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </search-head>
      <custom-tables
          :data="tableData"
          :total="total"
          noimport="true"
          isdel="true"
          title="参会人员列表"
          noexport="false"
          @selectionChange="selectionChange"
          @handleCurrentChange="handleCurrentChange"
          @handleSizeChange="handleSizeChange"
          @tableExport="tableExport"
          @tableDel="tableDel"
          @tableAdd="tableAdd">
        <el-table-column type="selection" align="center" width="55">
        </el-table-column>
        <el-table-column prop="userName" label="人员姓名" align="center">
        </el-table-column>
        <el-table-column prop="userCardId" label="证件号" align="center"></el-table-column>
        <el-table-column prop="projName" label="所属机构" align="center"></el-table-column>
        <el-table-column prop="userSex" label="性别" align="center">
          <template slot-scope="scope">
            <div>{{scope.row.userSex == 0 ? '男':'女'}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="userMobile" label="联系电话" align="center"></el-table-column>
        <el-table-column prop="userNote" label="备注说明" align="center"></el-table-column>
<!--        <el-table-column label="操作" align="center" width="100px">-->
<!--          <template slot-scope="scope">-->
<!--            <el-button type="text" link style="margin-right: 10px" @click="details(scope.row)">详情</el-button>-->
<!--            <el-button type="text" link style="margin-right: 10px" @click="delItem(scope.row)">删除</el-button>-->
<!--          </template>-->
<!--        </el-table-column>-->
      </custom-tables>
      <participantsEdit
          :visible.sync="showDialog"
          :title="dialogTitle"
          :item="item"
          :meet-id="meetId"
          @close="showDialog = false"
          @done="editDone"
      ></participantsEdit>
    </div>
  </custom-dialog>
</template>

<script>
import basic from "../../../api/modules/basic";
import meeting from "../../../api/modules/meeting";
import CustomDialog from "../../common/CustomDialog";
import CustomTables from "../../../components/common/CustomTables";
import SearchHead from "../../../components/common/SearchHead";
import {sexTypes} from '../../../utils/sexType'
import {downloadFile, organizationCascader} from "../../../utils/tool";
import participantsEdit from '../../../components/page/meeting/participantsEdit.vue'

export default {
  name: "meetingMange",
  components: {SearchHead, CustomTables, CustomDialog, participantsEdit},
  props: ["visible", "title", "isEdit","meetId"],
  data() {
    return {
      dialogTitle: "新增公司",
      showDialog: false,
      total: 0,
      tableData: [],
      searchForm: {
        currPage: 1,
        pageSize: 10,
      },
      item: {},
      sexList: sexTypes,
      areaParentList: [],
      orgaIdArr:[],
      copanyListInfo:[]
    };
  },
  watch:{
    meetId(newVal, oldval){
      if(newVal != oldval){
        this.getInfo(newVal);
      }
    }
  },
  mounted() {
    this.getCopanyInfo()
  },
  methods: {
    getCopanyInfo(){
      basic.getOrganizationTree({orgaPid:0}).then(res =>{
        // 递归遍历 生成 {value, label, children}
        let data = organizationCascader(res.data)
        this.copanyListInfo = data
      })
    },
    getInfo(meetId) {
      this.searchForm.meetId = meetId
      meeting.getMeetPersonList(this.searchForm).then((res) => {
        this.total = res.data.total;
        this.tableData = res.data.list;
      });
    },
    handleCurrentChange(pageIndex) {
      this.searchForm.currPage = pageIndex;
      this.getInfo();
    },
    handleSizeChange(pageSize) {
      this.searchForm.currPage = 1;
      this.searchForm.pageSize = pageSize;
      this.getInfo();
    },
    editDone() {
      // 编辑成功返回
      this.showDialog = false;
      this.getInfo();
    },
    headReset() {
      this.searchForm = {
        currPage: 1,
        pageSize: 10,
      };
      this.getInfo();
    },
    headSearch() {
      this.searchForm.currPage = 1;
      this.searchForm.pageSize = 10;
      this.getInfo();
    },
    tableDel() {
    },
    //导出
    tableExport() {
      let param = {
        currPage: this.searchForm.currPage,
        pageSize: this.searchForm.pageSize,
      };
      basic.getCompanyRecExport(param).then((res) => {
        downloadFile(res)
      }).catch(err => {
        console.log(err, 'err');
      })
    },
    //格式化json
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]));
    },
    tableAdd() {
      this.item = {};
      this.dialogTitle = "增加参会人员";
      this.showDialog = true;
    },
    selectionChange(val) {
      console.log(val);
    },
    editItem(item) {
      console.log(item);
      this.item = item;
      this.dialogTitle = "编辑公司";
      this.showDialog = true;
    },
    details() {

    },
    participants() {

    },
    colseDialog() {
      this.$emit("close");
    },

    delItem(item) {
      this.$confirm(`此操作将永久删除该公司, 是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        basic.delCompany({compId: item.compId}).then((res) => {
          this.$message({
            message: "删除成功",
            type: "success",
          });
          this.getInfo();
        });
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消删除",
        });
      });
    },
  },
};
</script>

<style scoped></style>
  
<template>
  <custom-dialog
    :visible.sync="visible"
    :title="title"
    :isFooter="isEdit == true ? true : false"
    width="50%"
    @close="colseDialog"
    @submit="submit"
    :isShow="isEdit == true ? true : false"
  >
    <el-form
      :model="editForm"
      label-width="120px"
      class="edit-form"
      :rules="rules"
      ref="editAcceptanceForm"
    >
      <el-row :gutter="20" class="edit-form-row">
        <el-col :span="12">
          <el-form-item label="会议名称：" prop="meetName">
            <el-input
              size="small"
              v-model="editForm.meetName"
              placeholder="请输入会议名称"
              :disabled="isEdit == true ? true : false"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="开始时间：" prop="startTime">
            <el-date-picker
            :disabled="isEdit == true ? true : false"
              v-model="editForm.startTime"
              type="datetime"
              placeholder="选择开始时间"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="所属酒店：" prop="hotelId">
            <el-select
              v-model="editForm.hotelId"
              placeholder="请选择所属酒店"
              :disabled="isEdit == true ? true : false"
              @change="hotelChange"
            >
              <el-option
                v-for="item in hotelList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="结束时间：" prop="endTime">
            <el-date-picker
            :disabled="isEdit == true ? true : false"
              v-model="editForm.endTime"
              type="datetime"
              placeholder="选择结束时间"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="会场：" prop="placeId">
            <el-select
             :disabled="isEdit == true ? true : false"
              v-model="editForm.placeId"
              placeholder="请选择会场"
             
            >
              <el-option
              :disabled="isEdit == true ? true : false"
                v-for="item in placeNoList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="主讲人：" prop="meetHost">
            <el-input
            :disabled="isEdit == true ? true : false"
              size="small"
              v-model="editForm.meetHost"
              placeholder="请输入主讲人"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <div class="line"></div>
        </el-col>
        <el-col :span="24">
          <el-form-item label="备注：" prop="meetNote">
            <el-input
              size="small"
              type="textarea"
              :rows="2"
              v-model="editForm.meetNote"
              placeholder="请输入备注"
              :disabled="isEdit == true ? true : false"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </custom-dialog>
</template>

<script>
import CustomDialog from "../../common/CustomDialog";
import config from "../../../api/config";
import meeting from "../../../api/modules/meeting";
import SourceCascader from "../SourceCascader";
import {turnTime} from '../../../utils/tool'
export default {
  name: "managementEdit",
  components: { SourceCascader, CustomDialog },
  props: ["visible", "title", "item", "isEdit"],
  data() {
    return {
      editForm: {
        annexUrl: [],
      },
      rules: {
        meetName: [
          { required: true, message: "请输入会议名称", trigger: "blur" },
        ],
        startTime: [
          { required: true, message: "请选择开始时间", trigger: "change" },
        ],
        hotelId: [
          { required: true, message: "请选择所属酒店", trigger: "blur" },
        ],
        endTime: [
          { required: true, message: "请选择结束时间", trigger: "blur" },
        ],
        placeId: [
          { required: true, message: "请选择会场", trigger: "blur" },
          // {
          //   pattern: /^(\+?86-?)?1[3-9]\d{9}$/,
          //   message: "请输入11位数的手机号码",
          //   trigger: "blur",
          // },
        ],
        meetHost: [
          { required: true, message: "请输入主讲人", trigger: "blur" },
        ],
      },
      uploadUrl: config.uploadUrl,
      projList: [],
      hotelList: [],
      placeNoList:[]
    };
  },
  watch:{
    'item':function(newVal,oldVal){
      console.log(newVal);
      if(newVal.meetId){
        this.editForm = {...newVal}
        this.getplaceNo(this.editForm.hotelId);
       
       
      }else{
        this.editForm ={}
        
      }

    },
    deep:true  
  },
  mounted() {
    this.gethotelList();
  },
  methods: {
    submit() {
      this.$refs.editAcceptanceForm.validate((valid) => {
        if (valid) {
      
          this.editForm.startTime =turnTime( this.editForm.startTime)
          this.editForm.endTime =turnTime( this.editForm.endTime)
          if(this.editForm.meetId){
            meeting.updateMeetingUserInfo(this.editForm).then((res) => {
            this.$message({
              message: "编辑成功",
              type: "success",
            });
            this.$emit("done");
            this.resetForm();
          });
          }else{
            meeting.AddMeetingInfo(this.editForm).then((res) => {
            this.$message({
              message: "新增成功",
              type: "success",
            });
            this.$emit("done");
            this.resetForm();
          });
          }
      
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    colseDialog() {
      this.$emit("close");
    },

    resetForm() {
      if (this.$refs.editAcceptanceForm) {
        this.$refs.editAcceptanceForm.clearValidate();
        this.$refs.editAcceptanceForm.resetFields();
      }
    },
    gethotelList() {
      meeting.getHotelList().then((res) => {
        res.data.map((item) => {
          this.hotelList.push({
            label: item.hotelName,
            value: item.hotelId,
          });
        });
      });
    },
    hotelChange(val) {
      this.getplaceNo(val);
    },
    //会场房间编号查询
    getplaceNo(val) {
      let param = {
        hotelId: val,
      };
      meeting.getMeetPlaceByHotelId(param).then((res) => {
        this.placeNoList = [];
        if (res.code == 200) {
          res.data.map((item) => {
            this.placeNoList.push({
              label: item.placeName,
              value: item.placeId,
            });
          });
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.edit-form {
  padding: 20px;
}
.line {
  width: 100%;
  height: 1px;
  background: #f0f0f0;
  margin-bottom: 15px;
}
.blankCol {
  height: 40px;
  margin-bottom: 20px;
}
.footer-save {
  position: absolute;
  bottom: 18px;
  right: 250px;
}
/deep/.el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  width: 250px !important;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  height: 32px !important;
  line-height: 32px !important;
  outline: 0;
  // padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
}
</style>
